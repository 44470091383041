import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { Tokens } from '../models/tokens';
import { Router } from '@angular/router';

import { API_URL } from 'src/app/shared/constat';

export const AUTH = {
  JWT_TOKEN:'JWT_TOKEN',
  REFRESH_TOKEN:'REFRESH_TOKEN',
  GROUP_ROLE:'GROUP_ROLE',

  ROLE:'ROLE',
  USER_ID:'USER_ID',
  PROFILE:'PRIFILE',

  ADMIN:'ADMIN',
  RESELLER:'RESELLER',
  CLIENT:'CLIENT'
}


export const InterceptorSkipHeader = new HttpHeaders({
  'X-Skip-Interceptor': ''
});

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = ''; //AppConfig.settings.api.url;

  private loggedUser: any;
  private token: any;
  private role:any;
  private userId:any;
  private profile:any;


  constructor(private http: HttpClient, private router: Router) {
   
      this.apiUrl =API_URL;
    
  }

  login(user: { email: string, password: string }): Observable<boolean> {

    // console.log("user : ",user)
    return this.http.post<any>(`${this.apiUrl}/auth/login`, user, { headers : InterceptorSkipHeader })
      .pipe(map((data: any) => {
            if(data && data.token.access) {
            		  //console.log(data);
            		  this.role = data.profile.role;
            		  this.userId = data.profile._id;
                  this.profile = data.profile;
                  
                      let t = {jwt : data.token.access, refreshToken: data.token.refresh}
                      this.doLoginUser(user.email, t)
                      return true
                    }
            return false;
          }),
          catchError((error: any) => {
            return of(false);
        }));
  }


  logout() {
    return this.http.delete<any>(`${this.apiUrl}/auth/logout`).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        //this.router.navigateByUrl('/auth/login');
        //alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }
  
  

  refreshToken() {
    
    return this.http.post<any>(`${this.apiUrl}/auth/refresh`, {
      'refreshToken': this.getRefreshToken()
    })
     .pipe(map((data:any) =>{
        if(data && data.token.access){
            let t = {jwt : data.token.access}
            this.storeJwtToken(t.jwt);
            return true
        } else {
            //this.doLogoutUser();
            return false;
        }
      }));
  }
  
  static getRole(){
	  return sessionStorage.getItem(AUTH.ROLE);
  }

  static getUserId(){
	  return sessionStorage.getItem(AUTH.USER_ID);
  }
  static getProfile(){
    let profile = sessionStorage.getItem(AUTH.PROFILE);
    if(profile){
      return JSON.parse(profile);
    }
    return {};
  }
 

  getJwtToken() {
    //console.log("JWT Token : ", sessionStorage.getItem(this.JWT_TOKEN))
	  return sessionStorage.getItem(AUTH.JWT_TOKEN);
  }

  private doLoginUser(email: string, tokens: Tokens) {
    this.loggedUser = email;
    this.storeTokens(tokens);
  }

  doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  doLockUser() {
    this.loggedUser = null;
    this.removeLockTokens();
  }

  getRefreshToken() {
    return sessionStorage.getItem(AUTH.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
	  sessionStorage.setItem(AUTH.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
	  sessionStorage.setItem(AUTH.JWT_TOKEN, tokens.jwt);
	  sessionStorage.setItem(AUTH.REFRESH_TOKEN, tokens.refreshToken);

	  sessionStorage.setItem(AUTH.USER_ID, this.userId);  
	  sessionStorage.setItem(AUTH.ROLE, this.role);
    sessionStorage.setItem(AUTH.PROFILE, JSON.stringify(this.profile));

  }

  private removeTokens() {
	  sessionStorage.removeItem(AUTH.JWT_TOKEN);
	  sessionStorage.removeItem(AUTH.REFRESH_TOKEN);

	  sessionStorage.removeItem(AUTH.ROLE);
	  sessionStorage.removeItem(AUTH.USER_ID);
    sessionStorage.removeItem(AUTH.PROFILE);
  }

  private removeLockTokens() {
	  sessionStorage.removeItem(AUTH.JWT_TOKEN);
	  sessionStorage.removeItem(AUTH.REFRESH_TOKEN);
	  sessionStorage.removeItem(AUTH.ROLE);
	  sessionStorage.removeItem(AUTH.USER_ID);
  }

}
