<div class="app-admin-wrap layout-sidebar-large">
    <div>
        <app-header-sidebar-large></app-header-sidebar-large>
    </div>
    <div class="side-content-wrap">
        <app-sidebar-large></app-sidebar-large>
    </div>

    <ng-scrollbar class="vh-100" visibility="hover" appearance="compact">
        <div class="main-content-wrap d-flex flex-column"
            [ngClass]="{'sidenav-open': navService.sidebarState.sidenavOpen}">
            <router-outlet></router-outlet>
            <div class="flex-grow-1"></div>
            <app-footer></app-footer>
            <div class="module-loader" *ngIf="moduleLoading">
                <div class="spinner spinner-bubble spinner-bubble-primary me-3"></div>
            </div>
        
        </div>
    </ng-scrollbar>

</div>
<app-search *ngIf="searchService.searchOpen"></app-search>