<div class="card o-hidden" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
  <div class="row ms-0">
    <div class="col-md-6 text-center " style="background-size: cover;background-image: url(./assets/images/photo-long-3.jpg)">
      <div class="pl-3 auth-right">
        <div class="auth-logo text-center mt-4">
          <img src="assets/images/logo.png" alt="">
        </div>
        <div class="flex-grow-1"></div>
        <div class="w-100 mb-4">
          <button class="btn btn-outline-primary btn-outline-email w-100 mb-2 btn-icon-text btn-rounded" routerLink="/sessions/signin">
            <i class="i-Mail-with-At-Sign"></i>
            Sign in with Email
          </button>
          <button class="btn btn-outline-primary btn-outline-google w-100 mb-2 btn-icon-text btn-rounded">
            <i class="i-Google-Plus"></i>
            Sign in with Google
          </button>
          <button class="btn btn-outline-primary btn-outline-facebook w-100 btn-icon-text btn-rounded">
            <i class="i-Facebook-2"></i>
            Sign in with Facebook
          </button>
        </div>
        <div class="flex-grow-1"></div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4">
        
        <h1 class="mb-3 text-18">Sign Up</h1>
        <!-- <p class="mb-4 text-muted font-small">Lorem ipsum dolor sit amet.</p> -->
        <form action="">
          <div class="form-group">
            <label for="username">Your name</label>
            <input id="username" class="form-control form-control-rounded" type="text">
          </div>
          <div class="form-group">
            <label for="email">Email address</label>
            <input id="email" class="form-control form-control-rounded" type="email">
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" class="form-control form-control-rounded" type="password">
          </div>
          <div class="form-group">
            <label for="repassword">Retype password</label>
            <input id="repassword" class="form-control form-control-rounded" type="password">
          </div>
          <button class="btn btn-primary w-100 btn-rounded mt-3">Sign Up</button>
        </form>
      </div>
    </div>
  </div>
</div>