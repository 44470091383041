<div class="card o-hidden" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
  <div class="row me-0">
    <div class="col-md-6">
      <div class="p-4">
        <div class="auth-logo text-center mb-4">
          <img src="assets/images/logo.png" alt="">
        </div>
        <h1 class="mb-3 text-18">Forgot Password</h1>
        <form action="">
          <div class="form-group">
            <label for="email">Email address</label>
            <input id="email" class="form-control form-control-rounded" type="email">
          </div>
          <button class="btn btn-primary w-100 btn-rounded mt-3">Reset Password</button>
          
        </form>
        <div class="mt-3 text-center">
          <a href="" class="text-muted" routerLink="/sessions/signin"><u>Sign in</u></a>
        </div>
      </div>
    </div>
    <div class="col-md-6 text-center " style="background-size: cover;background-image: url(./assets/images/photo-long-3.jpg)">
      <div class="pr-3 auth-right">
        <button class="btn btn-outline-primary btn-outline-email w-100 mb-2 btn-icon-text btn-rounded" routerLink="/sessions/signup">
          <i class="i-Mail-with-At-Sign"></i>
          Sign up with Email
        </button>
        <button class="btn btn-outline-primary btn-outline-google w-100 mb-2 btn-icon-text btn-rounded">
          <i class="i-Google-Plus"></i>
          Sign in with Google
        </button>
        <button class="btn btn-outline-primary btn-outline-facebook w-100 btn-icon-text btn-rounded">
          <i class="i-Facebook-2"></i>
          Sign in with Facebook
        </button>
      </div>
    </div>
  </div>
</div>