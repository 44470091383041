import { Component, OnInit ,Input } from '@angular/core';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { catchError} from 'rxjs/operators';
import { Observable, of} from 'rxjs';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
    animations: [SharedAnimations]
})
export class SigninComponent implements OnInit {
    @Input() data: any;
    loading: boolean;
    loadingText: string;
    signinForm: UntypedFormGroup;

    public errors:any = {};
    public isSubmitted = false;
	public successMessage: any;
    public errorMessage = "Some fields are not a valid.";
    public isFormSucces = false;
    public isFormError = false;



    constructor(
        private fb: UntypedFormBuilder,
        private auth: AuthService,
        private router: Router,
        private toastr: ToastrService,
    ) { }


    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
                this.loadingText = 'Loading Dashboard Module...';

                this.loading = true;
            }
            if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
                this.loading = false;
            }
        });

        this.signinForm = this.fb.group({
            email: ['',Validators.required],
            password: ['']
        });

        if (this.data) this.signinForm.patchValue(this.data);
    }

    signin() {

      this.setErrors({})
      if (!this.signinForm.valid) {
        this.signinForm.markAllAsTouched();
        this.toastr.error('Please fill out the form correctly.', 'Error!', { progressBar: true });
        return;
      }
        this.loading = true;
        this.loadingText = 'Sigining in...';

        this.auth.login(this.signinForm.value)
        .pipe(catchError((error: any, caught: Observable<any>): Observable<any> => {
            // console.error('There was an error!', error);
            this.loading = false;
            return of();
        }))
        .subscribe(data => {
            this.loading = false;
            // console.log("data : ", data);
            this.router.navigateByUrl('/client');
        });
    }

    getErrorMessage(controlName: string): string | null {
        const control = this.signinForm.get(controlName);
        if (control?.errors) {
          if (control.errors['required']) {
            return 'This field is required.';
          }
          if (control.errors['minlength']) {
            const minLength = control.errors['minlength'].requiredLength;
            return `Minimum length is ${minLength} characters.`;
          }
          if (control.errors['email']) {
            return 'Please enter a valid email address.';
          }
        }
        return null;
      }


      onSuccess(data:any,message: string) {

        this.loading = false;
        this.toastr.success(data.message || message, 'Success!', { progressBar: true });
    
      }
    
      onError(errors:any,message: string) {
        this.loading = false;
        this.setErrors(errors.error.error || {});
        this.toastr.error(errors.error.error.message || message, 'Error!', { progressBar: true, easeTime:600 });           
        }


      getError(key:string){
        return this.errors[key] || this.getErrorMessage(key) || null;
      }
    
      public setErrors(errors:any){
        //console.log("errors ....... ",errors);
            this.errors = errors;
        }
        
        public isError(key:string)
        {
        //console.log("iserror : ",this.form.status)
        if(this.isSubmitted){
          if(key in this.errors){
            return 'is-invalid';
          }
          else{
            return 'is-valid';
          }
        }
        else{
          return '';
        }
            
        }

}
