import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

import { AuthGuard } from './auth/guards/auth.guard';
import { AppGuard } from './auth/guards/app.guard';


const adminRoutes: Routes = [
  
   {
      path: 'client',
      loadChildren: () => import('./views/server_management/client/client.module').then(m => m.ClientModule)
    },
    {
      path: 'user',
      loadChildren: () => import('./views/server_management/user/user.module').then(m => m.UserModule)
    },

    {
      path: 'server',
      loadChildren: () => import('./views/server_management/server/server.module').then(m => m.ServiceModule)
    },

    {
      path: 'download',
      loadChildren: () => import('./views/server_management/download/download.module').then(m => m.DownloadModule)
    },
            
  ];

  

  const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
    // {
    //   path: '',
    //   redirectTo: 'dashboard/v1',
    //   pathMatch: 'full'
    // },
    {
      path: '',
      component: AuthLayoutComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: 'auth',
          loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
        }
      ]
    },
    {
      path: '',
      component: BlankLayoutComponent,
      children: [
        {
          path: 'others',
          loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
        }
      ]
    },
    {
      path: '',
      component: AdminLayoutSidebarLargeComponent,
      canActivate: [AppGuard],
      canLoad: [AppGuard],
      children: adminRoutes
    },
    {
      path: '**',
      redirectTo: 'client'
    }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
