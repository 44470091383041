<div class="search-ui" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
	<div class="search-header d-flex">
	  <img src="./assets/images/logo.png" alt="" class="logo">
	  <div class="m-auto"></div>
	  <button class="btn btn-icon bg-transparent float-right mt-2" (click)="searchService.searchOpen = false">
		<i class="i-Close-Window text-22 text-muted"></i>
	  </button>
	</div>
  
	<input type="text" placeholder="Type here" class="search-input" [formControl]="searchCtrl" autofocus>
  
	<div class="search-title">
	  <span class="text-muted">Search results</span>
	</div>
  
	<ng-scrollbar style="height: calc(100vh - 350px);">
	  <table class="table table-striped">
		<thead>
		  <tr>
			<th>Icon</th>
			<th>Client ID</th>
			<th>Name</th>
			<th>Description</th>
			<th>Type</th>
		  </tr>
		</thead>
		<tbody>
		  <tr *ngFor="let item of data$ | async; let i = index;" [@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
			<td>
				<img src="./assets/images/server.png" alt="" style="cursor: pointer;"(click)="navigateServer(item.id)">
			  </td>
			<td>{{item?.client_id}}</td>
			<td> <a (click)="navigateServer(item.id)" style="font-weight: bold; cursor: pointer;">{{item.name_new.title}}</a></td>
			<td>{{item?.description}}</td>
			<td style="font-weight: bold;">{{item?.type}}</td>
		  </tr>
		</tbody>
	  </table>
	</ng-scrollbar>
  
	<!-- PAGINATION CONTROL -->
	<div class="col-md-12 mt-3" *ngIf="totalItems > 0">
	  <pagination-controls (pageChange)="page = $event; loadData()"></pagination-controls>
	</div>
  </div>
   