import { environment } from "src/environments/environment";

export const API_BASE_URL = "https://monitor.redtons.com/api/";
//  export const API_BASE_URL = "http://0.0.0.0:5001/";


export let API_URL;

if(environment.production){
    API_URL = API_BASE_URL+"v1/admin";
}
else{
    API_URL = API_BASE_URL+"v1/admin";
}




