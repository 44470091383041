// invoice.service.ts
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './base-api.service';

@Injectable({
    providedIn: 'root'
})
export class CurdApiService {

    uri = ""
    constructor(private apiService:ApiService,
        private http: HttpClient

    ) {
    
    }

    setUri(uri:string){
        this.uri = uri;
    }

    getList(paramsObj?: any) {
        let params = new HttpParams();
        if (paramsObj) {
            Object.keys(paramsObj).forEach(key => {
                if (paramsObj[key] !== undefined && paramsObj[key] !== null) {
                    params = params.set(key, paramsObj[key]);
                }
            });
        }
        return this.apiService.get<any[]>(this.uri, { params });
    }

      
    

    get(id: string) {
        return this.apiService.get<any>(`${this.uri}/${id}`);
    }

    save(model: any) {
        if (model.id) {
            return this.apiService.put<any>(`${this.uri}/${model.id}`, model);
        } else {
            return this.apiService.post<any>(this.uri, model);
        }
    }

    post(model: any) {
        return this.apiService.post<any>(this.uri, model);
    }

    put(id: string,model:any) {
        return this.apiService.put<any>(`${this.uri}/${id}`, model);
    }

    delete(id: string) {
        return this.apiService.delete<any>(`${this.uri}/${id}`);
    }

    getAll(paramsObj?: any) {
        let params = new HttpParams();
        if (paramsObj) {
            Object.keys(paramsObj).forEach(key => {
                if (paramsObj[key] !== undefined && paramsObj[key] !== null) {
                    params = params.set(key, paramsObj[key]);
                }
            });
        }
        return this.apiService.get<any[]>(this.uri, { params });
    }


}
