import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CurdApiService } from 'src/app/shared/services/curd-api.service';
import { SearchService } from '../../services/search.service';
import { SharedAnimations } from '../../animations/shared-animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [SharedAnimations]
})
export class SearchComponent implements OnInit {
  page = 1;
  pageSize = 6;
  totalItems = 0;
  data$: Observable<any[]> = of([]);
  searchCtrl: UntypedFormControl = new UntypedFormControl('');

  constructor(
    public searchService: SearchService,
    private api: CurdApiService,
    private router: Router
  ) {
    this.api.setUri('server');
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const params = { page: this.page };
    this.api.getList(params).pipe(
      tap((response: any) => {
        this.totalItems = response.metadata.pagination.total;
        this.data$ = of(response.data.map((item: any) => ({
          ...item,
          name_new: {
            title: item.name,
            uri: "/server/detail/" + item.id
          }
        })));
      })
    ).subscribe();
  }



  navigateServer(id) {
    // console.log("navigate : ",id)
    this.searchService.searchOpen = false;
    this.router.navigate(["server","detail",id]);  
  }
}
