import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

export const InterceptorSkip = 'X-Skip-Interceptor';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //set language header
    let lang = {
      setHeaders: {
        'Accept-Language': 'en'
      }
    }
    request = request.clone(lang)

    if (request.headers && request.headers.has(InterceptorSkip))
    {
        //console.log("skip interceptor");
        const headers = request.headers.delete(InterceptorSkip);
        return next.handle(request.clone({ headers }));
    }

    if (this.authService.getJwtToken() && !this.isRefreshing) {
      request = this.addToken(request, this.authService.getJwtToken());
    } else {
      request = this.addRefreshToken(request, this.authService.getRefreshToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (this.isRefreshing) {
          //console.log('Refresh Token has been expired.')
          this.authService.doLogoutUser()
          this.router.navigate(['/auth/logout']);
          return this.handle401Error(request, next);
        } else {
          return this.handle401Error(request, next);
        }
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string | null) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private addRefreshToken(request: HttpRequest<any>, token: string | null) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
