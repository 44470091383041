<div class="card o-hidden" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
   <div class="row me-0">
    <div class="col-md-12">
      <div class="p-4">
        <div class="auth-logo text-center mb-4">
          <img src="assets/images/logo.png" alt=""></div>
          <h1 class="mb-3 text-18">Sign In</h1>
          <form [formGroup]="signinForm" (ngSubmit)="signin()">
          <div class="form-group">
            <label for="email">Email address</label>
            <input id="email" class="form-control form-control-rounded" [ngClass]="isError('email')" formControlName="email" type="email">
            <div class="invalid-feedback">{{getError('email')}}</div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" class="form-control form-control-rounded" [ngClass]="isError('password')" formControlName="password" type="password">
            <div class="invalid-feedback">{{getError('password')}}</div>
          </div>
          <!-- <button class="btn btn-rounded btn-primary w-100 mt-2">Sign In</button> -->
          <btn-loading btnClass="btn-primary btn-rounded w-100 mt-3" [loadingText]="loadingText" [loading]="loading">
            Sign In
          </btn-loading>
        </form>       
      </div>
    </div>  
  </div>
</div>