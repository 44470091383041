// base-api.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from 'src/app/shared/constat';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    protected apiUrl = API_URL;

    constructor(protected http: HttpClient) { }

    get<T>(uri: string,params:any = {}): Observable<T> {
        return this.http.get<T>(`${this.apiUrl}/${uri}`,{params});
    }

    post<T>(uri: string, body: any): Observable<T> {
        return this.http.post<T>(`${this.apiUrl}/${uri}`, body);
    }

    put<T>(uri: string, body: any): Observable<T> {
        return this.http.put<T>(`${this.apiUrl}/${uri}`, body);
    }

    delete<T>(uri: string): Observable<T> {
        return this.http.delete<T>(`${this.apiUrl}/${uri}`);
    }
}
